@function get-color($mode, $key) {
  @if map-has-key($themes, $mode) {
    $theme: map-get($themes, $mode);
    @if map-has-key($theme, $key) {
      @return map-get($theme, $key);
    }
  }
  @return red; // default color for debugging purpose
}

@function get-light-color($key) {
  @return get-color('light', $key);
}

@function get-dark-color($key) {
  @return get-color('dark', $key);
}

@mixin reset-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin section-title-adjustment() {
  h1 > span {
    margin-top: -55px; /* Size of fixed header */
    padding-bottom: 55px;
    display: block;
  }
}

@mixin brand-background() {
  @each $brand, $color in $brand-colors {
    .bg-#{$brand} {
      background-color: $color;
    }
  }
}

@mixin transition() {
  transition: all $transition-type $transition-duration;
}

@mixin selection-color($theme: 'light') {
  background: get-light-color('accent-color');
  color: get-light-color('text-over-accent-color');
  @if $theme == 'dark' {
    background: get-dark-color('accent-color');
    color: get-dark-color('text-over-accent-color');
  }
}

@function get-alert-bg-color($type, $mode) {
  $colors: map-get($alerts, $type);
  @if $mode == 'light' {
    @return map-get($colors, 'bg-color');
  } @else {
    @return map-get($colors, 'text-color');
  }
  @return red;
}

@function get-alert-text-color($type, $mode) {
  $colors: map-get($alerts, $type);
  @if $mode == 'light' {
    @return map-get($colors, 'text-color');
  } @else {
    @return map-get($colors, 'bg-color');
  }
  @return red;
}
