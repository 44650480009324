.pdf-viewer {
  canvas {
    border: 1px solid black;
    direction: ltr;
    width: 100%;
    height: auto;
    display: none;
  }
  
  .paginator {
    display: flex!important;
    width: 100% !important;
    text-align: center;
    margin-bottom: 0.2rem !important;
    justify-content: space-between;

    .page-number-indicator {
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  .loading-wrapper {
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 350px;
  }
  
  .loading {
    display: inline-block;
    width: 50px;
    height: 50px;
    border: 3px solid #d2d0d0;;
    border-radius: 50%;
    border-top-color: #383838;
    animation: spin 1s ease-in-out infinite;
    -webkit-animation: spin 1s ease-in-out infinite;
  }
  
  @keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
  @-webkit-keyframes spin {
    to { -webkit-transform: rotate(360deg); }
  }
}