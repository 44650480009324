.paginator {
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  vertical-align: bottom;

  .page-item {
    & > a {
      color: get-light-color('accent-color');
    }
    &.active,
    &:hover > a {
      background-color: get-light-color('accent-color') !important;
      color: get-light-color('text-over-accent-color') !important;
    }
  }
}

pre {
  margin: 5px;
  & > code {
    padding: 10px !important;
  }
}

@include media('<=large') {
  pre {
    margin: 0px;
    & > code {
      padding: 0px !important;
    }
  }
}
 $alert-types: ('success', 'info', 'warning', 'danger');

.alert {
  @each $type in $alert-types {
    &.#{$type} {
      background: get-alert-bg-color($type, 'light');
      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: get-alert-text-color($type, 'light') !important;
      }
      strong {
        padding-left: 0.5rem;
        color: get-alert-text-color($type, 'light') !important;
      }
    }
  }
}

html[data-theme='dark'] {
  .paginator {
    .page-item {
      & > a {
        color: get-dark-color('accent-color');
      }
      &.active,
      &:hover > a {
        background-color: get-dark-color('accent-color') !important;
        color: get-dark-color('text-over-accent-color') !important;
      }
    }
  }
  .alert {
    @each $type in $alert-types {
      &.#{$type} {
        background: get-alert-bg-color($type, 'dark');
        svg {
          color: get-alert-text-color($type, 'dark') !important;
        }
        strong {
          color: get-alert-text-color($type, 'dark') !important;
        }
      }
    }
  }
}
