.home {
  height: 100vh;
  padding: 0;
  margin: 0;
  color: get-light-color('text-over-accent-color');
  overflow: hidden;

  .background {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;
    background-attachment: fixed;
    background-position: center;
    transform: scale(1.1);
    filter: blur(3px);
    background-size: cover;
  }

  .arrow-center {
    display: flex;
    justify-content: center;
  }

  /*
    Resolves https://github.com/hugo-toha/toha/issues/70
  
    fixed attached images use the whole <body> size. On mobile this can get really
    tall which blows your image out. Setting the attachment back to scroll allows
    your cover image to stretch within its own container
  */
  @supports (-webkit-touch-callout: none) {
    .background {
      background-attachment: scroll;
    }
  }

  .content {
    position: relative;
    top: -65%;
    height: 60%;
  }

  img {
    width: 148px;
    height: 148px;
    background-color: get-light-color('bg-secondary');
    padding: 5px;
    margin-bottom: 10px;
  }

  .greeting,
  .greeting-subtitle {
    color: get-light-color('text-over-accent-color');
  }

  .typing-carousel {
    font-size: 14pt;
    color: get-light-color('text-over-accent-color');
  }

  #typing-carousel-data {
    display: none;
  }

  .arrow {
    position: absolute;
    color: get-light-color('text-over-accent-color');
    font-size: 1.5rem;
    bottom: 0;
  }

  .bounce {
    animation: bounce 2s infinite;
  }

  @keyframes bounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

  @include media('<=small') {
    .content {
      position: relative;
      top: -75%;
      height: 65%;
    }

    img {
      width: 140px;
      max-width: 50%;
      height: auto;
    }

    .greeting {
      font-size: 24pt;
    }
  }
}

html[data-theme='dark'] {
  .home {
    color: get-dark-color('text-over-accent-color');
    img {
      background-color: get-dark-color('bg-secondary');
    }

    .greeting,
    .greeting-subtitle {
      color: get-dark-color('text-over-accent-color');
    }

    .typing-carousel {
      color: get-dark-color('text-over-accent-color');
    }
    .arrow {
      color: get-dark-color('text-over-accent-color');
    }
  }
}
