table {
  border-radius: 0.1rem;
  border: 1px solid rgba(get-light-color('accent-color'), 0.1);
  min-width: 10rem;
  padding: 0.1rem;
  thead {
    tr {
      background: get-light-color('accent-color');
      color: get-light-color('text-over-accent-color');
    }
  }

  tbody {
    tr {
      height: 40px !important;
      color: get-light-color('text-color');
      &:nth-child(odd) {
        background-color: get-light-color('bg-primary');
      }
      &:nth-child(even) {
        background-color: rgba(get-light-color('accent-color'), 0.05);
      }
    }
  }
  th,
  td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.gist {
  table {
    border-radius: unset !important;
    background: unset !important;
    border: unset !important;
    padding: unset !important;

    tr {
      height: unset !important;
    }

    th,
    td {
      padding: unset !important;
      border-left: unset !important;
      border-bottom: unset !important;
    }
    td,
    tc {
      border-right: 1px solid rgba(get-light-color('accent-color'), 0.1);
    }

    thead {
      tr {
        background: unset !important;
        color: unset !important;
      }
    }

    tbody {
      tr {
        &:nth-child(odd) {
          background-color: unset !important;
        }
        &:hover {
          background: unset !important;
        }
      }
    }
  }
}

html[data-theme='dark'] {
  table {
    border: 1px solid rgba(get-dark-color('accent-color'), 0.1);
    thead {
      tr {
        background: get-dark-color('accent-color');
        color: get-dark-color('text-over-accent-color');
      }
    }

    tbody {
      tr {
        color: get-dark-color('text-color');
        &:nth-child(odd) {
          background-color: get-dark-color('bg-primary');
        }
        &:nth-child(even) {
          background-color: rgba(get-dark-color('accent-color'), 0.05);
        }
      }
    }
  }
  .gist {
    &::selection{
      background: get-dark-color('text-color');
      color: get-dark-color('inverse-text-color');
    }
    table {
      td,
      tc {
        border-right: 1px solid rgba(get-dark-color('accent-color'), 0.1);
      }
    }
  }
}
