.sidebar-section {
  order: 1;
  flex: 20%;
  max-width: 20%;
  @include transition();

  .sidebar-holder {
    top: 2.5rem;
    position: sticky;
    background-color: get-light-color('bg-primary');
    height: 100vh;
    overflow: auto;
    box-shadow: $box-shadow;
    @include transition();

    .sidebar {
      background: get-light-color('bg-primary');
      height: 100vh;
      @include transition();

      #search-box {
        margin-left: 5%;
        margin-right: 5%;
        width: -webkit-fill-available;
        height: 35px;
        padding-left: 15px;
        margin-top: 30px;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: get-light-color('bg-secondary');
        @include transition();
        border: 1px solid rgba(get-light-color('accent-color'), 0.1);

        &:focus {
          border: 1pt solid get-light-color('accent-color');
          outline: none;
        }
      }

      #list-heading {
        padding-left: 0px !important;
      }

      .sidebar-tree {
        padding-left: 1rem;
        position: relative;
        width: -moz-max-content;
        width: max-content;

        .tree {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin: 0;
            padding: 0 1em;
            line-height: 2em;
            color: get-light-color('heading-color');
            position: relative;

            i {
              color: get-light-color('heading-color');
              font-size: 12px;
              margin-right: 5px;
            }
          }
          .shift-right {
            margin-left: 5px;
          }

          .subtree {
            padding-left: 0.7rem;

            &:before {
              content: '';
              display: block;
              width: 0;
              position: absolute;
              top: 2.5rem;
              left: 1.5rem;
              bottom: 0.9rem;
              border-left: 1px solid get-light-color('text-color');
            }
            li {
              &:before {
                content: '';
                display: block;
                width: 18px;
                height: 0;
                border-top: 1px solid get-light-color('text-color');
                margin-top: -1px;
                position: absolute;
                top: 18px;
                left: -2px;
              }
              &:last-child:before {
                background: get-light-color('bg-primary');
                height: auto;
                top: 1.1rem;
                bottom: 0;
              }
              ul {
                position: relative;
                padding-left: 0.5rem;
                display: none;
                list-style: none;

                &.active {
                  display: block;
                }

                &:before {
                  content: '';
                  display: block;
                  width: 0;
                  position: absolute;
                  top: -8px;
                  left: 6px;
                  bottom: 0.8rem;
                  border-left: 1px solid;
                }
              }
            }
          }
        }
      }
    }
  }

  @include media('<=ultra-large') {
    flex: 15%;
    max-width: 15%;

    .sidebar-holder {
      max-width: 100%;
    }
  }

  @include media('<=extra-large') {
    .sidebar-holder {
      max-width: 20rem;
    }
  }

  @include media('<=very-large') {
    .sidebar-tree {
      margin-left: 1rem;
    }
  }

  @include media('<very-large') {
    flex: 0%;
    max-width: 0%;
    min-height: 100vh;
    @include transition();

    .sidebar-holder {
      position: sticky;
      top: 2.5rem;
      width: 100%;
      padding-bottom: 1rem;
      max-height: calc(100vh - 2.5rem);
    }

    &.expanded {
      flex: 30%;
      max-width: 30%;
      margin-right: 0.5rem;
      @include transition();
    }
  }

  @include media('<=medium') {
    flex: 0%;
    max-width: 0%;
    min-height: 100vh;
    @include transition();

    .sidebar-holder {
      position: sticky;
      top: 2.5rem;
      width: 100%;
    }

    &.expanded {
      flex: 40%;
      max-width: 40%;
      margin-right: 0.5rem;
      @include transition();
    }
  }

  @include media('<=small') {
    width: 100%;
    min-height: 0;
    max-height: 0;
    max-width: 100%;
    @include transition();

    .sidebar-holder {
      max-height: 0;
      height: -moz-fit-content;
      height: fit-content;
      overflow: hidden;
      max-width: 100%;
      @include transition();

      .sidebar {
        position: relative;
        height: -moz-fit-content;
        height: fit-content;
        max-height: -moz-fit-content;
        max-height: fit-content;
        width: 100vw;
        min-height: 0;
        overflow: hidden;
        @include transition();

        .sidebar-tree {
          margin-left: 0rem;
          max-height: 0;
          @include transition();
        }
      }
    }

    &.expanded {
      margin-top: 2rem;
      position: relative;
      height: -moz-fit-content;
      height: fit-content;
      flex: none;
      max-height: 300vh;
      max-width: 100%;
      @include transition();

      .sidebar-holder {
        max-height: 200vh;
        @include transition();

        .sidebar-tree {
          max-height: 200vh;
          @include transition();
        }
      }
    }
  }
}

.feather-plus-circle,
.feather-minus-circle {
  width: 1rem;
  height: 1rem;
}

html[data-theme='dark'] {
  .sidebar-section {
    .sidebar-holder {
      background-color: get-dark-color('bg-primary');

      .sidebar {
        background: get-dark-color('bg-primary');

        #search-box {
          background-color: get-dark-color('bg-secondary');
          border: 1px solid rgba(get-dark-color('accent-color'), 0.1);

          &:focus {
            border: 1pt solid get-dark-color('accent-color');
          }
        }

        .sidebar-tree {
          .tree {
            li {
              color: get-dark-color('heading-color');

              i {
                color: get-dark-color('heading-color');
              }
            }
            .subtree {
              &:before {
                border-left: 1px solid get-dark-color('text-color');
              }
              li {
                &:before {
                  border-top: 1px solid get-dark-color('text-color');
                }
                &:last-child:before {
                  background: get-dark-color('bg-primary');
                }
              }
            }
          }
        }
      }
    }
  }
}
