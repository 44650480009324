.achievements-section {
  @include section-title-adjustment();

  .container {
    padding-top: 0.5rem;
  }

  #gallery {
    .achievement-entry {
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 7px;
      margin-right: 7px;
      z-index: 1;
      background-color: get-light-color('bg-secondary');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      overflow: hidden;
      @include transition();

      .title {
        color: get-light-color('inverse-text-color');
        background-color: rgba(get-light-color('bg-primary-inverse'), 0.7);
        opacity: 0;
        padding: 5px;
        position: absolute;
        bottom: 0px;
        width: 100%;
        margin-bottom: 0px;
        bottom: -5px;
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.1);
        @include transition();
        z-index: 20000;

        .svg-inline--fa {
          opacity: 1;
          font-size: 1rem;
          @include transition();
        }

        .title {
          opacity: 1;
          bottom: 0px;
          @include transition();
        }
      }
    }

    .achievement-details {
      cursor: pointer;
      z-index: 1;
      height: 75vh !important;
      opacity: 1 !important;
      transition: none !important;
      transform: none !important;

      .img-type-1 .svg-inline--fa,
      .img-type-2 .svg-inline--fa {
        margin-top: 0px !important;
        transition: none !important;
        float: right;
      }
    }

    .img-type-1 {
      height: 300px;

      .svg-inline--fa {
        margin-top: 135px;
      }
    }
    .img-type-2 {
      height: 146px;
      margin-bottom: 8px;

      .svg-inline--fa {
        margin-top: 50px;
      }
    }

    .svg-inline--fa {
      color: get-light-color('muted-text-color');
      background-color: rgba(get-light-color('bg-primary-inverse'), 0.7);
      padding: 10px;
      font-size: 0rem;
      opacity: 0;
    }
    .caption {
      background-color: rgba(get-light-color('bg-primary-inverse'), 0.7);
      bottom: 1rem;
      left: 1rem;
      color: get-light-color('inverse-text-color');
      padding: 15px;
      position: absolute;
      @include transition();

      h4 {
        color: get-light-color('inverse-text-color');
      }
      p {
        font-size: 16px;
        font-weight: 300;
        color: get-light-color('inverse-text-color');
      }
    }

    @include media('<=medium') {
      .container {
        max-width: 100%;
      }
      .col-md-6 {
        flex: 50%;
        width: 50%;
      }
    }

    @include media('<=small') {
      #gallery .achievement-entry:hover {
        transform: scale(1.05);
      }
    }
  }
}

html[data-theme='dark'] {
  .achievements-section {
    #gallery {
      .achievement-entry {
        background-color: get-dark-color('bg-secondary');

        .title {
          color: get-dark-color('inverse-text-color');
          background-color: rgba(get-dark-color('bg-primary-inverse'), 0.7);
        }
      }
      .svg-inline--fa {
        color: get-dark-color('muted-text-color');
        background-color: rgba(get-dark-color('bg-primary-inverse'), 0.7);
      }
      .caption {
        background-color: rgba(get-dark-color('bg-primary-inverse'), 0.7);
        color: get-dark-color('inverse-text-color');

        h4 {
          color: get-dark-color('inverse-text-color');
        }
        p {
          color: get-dark-color('inverse-text-color');
        }
      }
    }
  }
}
