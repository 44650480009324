.skills-section {
  .card {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    height: 100%;

    .card-head {
      background-color: get-light-color('bg-primary') !important;
      height: -moz-fit-content;
      height: fit-content;
      padding: 0.7rem;
      padding-bottom: 0rem;
      border-bottom: 0.0625rem solid rgba(get-light-color('accent-color'), 0.4);

      .card-img-xs {
        margin-right: 0.5rem;
        margin-bottom: 0.75rem;
      }
    }

    .card-body {
      padding-top: 0.2rem;
      padding-left: 0.7rem;
    }
  }

  @include media('<=medium') {
    padding-left: 0;
    padding-right: 0;

    .container {
      max-width: 95%;
    }
  }
}

html[data-theme='dark'] {
  .skills-section {
    .card {
      .card-head {
        background-color: get-dark-color('bg-card') !important;
        border-bottom: 0.0625rem solid rgba(get-dark-color('accent-color'), 0.4);
      }
    }
  }
}
