.publications-section {
  @include section-title-adjustment();

  .card {
    background: get-light-color('bg-card');
    border-top: 2px solid get-light-color('accent-color');

    &:hover,
    &:focus {
      border-top: 2px solid get-light-color('accent-color');
    }

    .card-header {
      background: none;
      border: none;
      display: flex;
      flex-direction: column;

      .sub-title {
        color: get-light-color('muted-text-color');
        margin-top: 0.4rem;

        span:nth-child(2) {
          float: right !important;
        }
      }
    }

    .card-body {
      padding: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .card-footer {
      background: get-light-color('bg-card');
      border: none;
      padding: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-bottom: 0.3rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  .filtr-publications {
    padding: 1rem !important;
  }

  .btn-group {
    justify-content: center;
  }

  @include media('<=large') {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .container {
      max-width: 100%;
    }

    .filtr-publications {
      padding: 0;
    }

    .pub-filtr-item {
      padding-left: 0.2rem;
      padding-right: 0.2rem;
    }
  }

  @include media('<=medium') {
    .pub-filtr-item {
      flex: 100%;
    }
  }

  @include media('<=small') {
    .pub-filtr-item {
      flex: 100%;
      max-width: 100%;
    }
    .details-btn {
      .btn {
        margin-top: 0.5rem;
        margin-left: auto;
      }
    }

    .card {
      .card-footer {
        padding-left: 0.5rem;

        .tags {
          flex: 100%;
          max-width: 100%;

          .btn {
            margin-top: 0.2rem;
          }
        }
      }
    }
  }
}

html[data-theme='dark'] {
  .publications-section {
    .card {
      background: get-dark-color('bg-card');
      border-top: 2px solid get-dark-color('accent-color');

      &:hover,
      &:focus {
        border-top: 2px solid get-dark-color('accent-color');
      }

      .card-header {
        .sub-title {
          color: get-dark-color('muted-text-color');
        }
      }

      .card-footer {
        background: get-dark-color('bg-card');
      }
    }
  }
}
