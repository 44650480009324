.projects-section {
  .card {
    .card-header {
      background-color: get-light-color('bg-card');
      padding: 0.7rem;
      padding-bottom: 0rem;
      text-decoration: none;

      .card-img-xs {
        margin-right: 0.5rem;
      }
      .sub-title {
        color: get-light-color('muted-text-color');
        margin-top: 0.4rem;

        span {
          &:nth-child(1) {
            float: left;
          }
          &:nth-child(2) {
            float: right;
          }
        }
      }
    }

    .card-body {
      padding: 0.7rem;
    }
  }

  .filtr-projects {
    padding: 1rem !important;
  }

  .project-card-footer {
    display: flex;
    .badge {
      margin-left: 0.1em;
      margin-right: 0.1em;
    }
  }

  .project-tags-holder {
    width: 70%;
  }

  .project-btn-holder {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    span {
      display: flex;
      justify-content: flex-end;
    }
  }

  @include media('<=large') {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .container {
      max-width: 100%;
    }
    .filtr-projects {
      padding: 0;
    }
    .filtr-item {
      flex: 50%;
      padding-left: 0.2rem;
      padding-right: 0.2rem;
      max-width: calc(100% / 2 - 0.2rem);
    }
  }

  @include media('<=small') {
    .btn {
      margin-top: 0.3125rem;
    }
    .filtr-item {
      max-width: 100%;
    }
  }
}

html[data-theme='dark'] {
  .projects-section {
    .card {
      .card-header {
        background-color: get-dark-color('bg-card');
        .sub-title {
          color: get-dark-color('muted-text-color');
        }
      }
    }
  }
}
