.accomplishments-section {
  @include section-title-adjustment();

  .card {
    background: get-light-color('bg-card');
    border-top: 2px solid get-light-color('accent-color');
    height: 100%;

    &:hover,
    &:focus {
      border-top: 2px solid get-light-color('hover-over-accent-color');
    }

    .card-header {
      background: none;
      border: none;

      .sub-title {
        color: get-light-color('muted-text-color');
        margin-top: 0.4rem;
      }
    }

    .card-body {
      padding: 0;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .card-footer {
      background: get-light-color('bg-card');
      border: none;
      padding: 0;
      padding-left: 0.7rem;
      padding-bottom: 0.3rem;
    }
  }

  @include media('<=large') {
    width: 100%;
    padding: 0;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    margin-top: 2rem;

    .container {
      max-width: 100%;
    }
  }

  @include media('<=small') {
    flex: 100%;
    max-width: 100%;
  }
}

html[data-theme='dark'] {
  .accomplishments-section {
    .card {
      background: get-dark-color('bg-card');
      border-top: 2px solid get-dark-color('accent-color');

      &:hover,
      &:focus {
        border-top: 2px solid get-dark-color('hover-over-accent-color');
      }

      .card-header {
        .sub-title {
          color: get-dark-color('muted-text-color');
        }
      }
      .card-footer {
        background: get-dark-color('bg-card');
      }
    }
  }
}
